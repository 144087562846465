<template>
  <el-collapse
    v-model="activeCollapseItems"
    class="ss-collapse"
  >
    <el-collapse-item
      v-for="(setting, day, dayIndex) in innerWorktimes"
      :key="dayIndex"
      :title="$t('schedule.setting.weekdayShift', { weekday: $t(`schedule.base.${day}`) })"
      :name="dayIndex"
    >
      <single-work-hours
        v-if="isYKSchedule"
        :hours.sync="innerWorktimes[day].hours"
        @update:hours="emitData"
      />
      <template v-else>
        <single-shift
          v-for="(shift, shiftIndex) in setting.ranges"
          :key="`${day}-${shiftIndex}`"
          :time.sync="innerWorktimes[day].ranges[shiftIndex]"
          @update:time="emitData"
          @delete="deleteShift(day, shiftIndex)"
        />
        <schedule-button
          type="secondary"
          @click="addShift(day)"
        >
          <i class="el-icon-plus" />
          {{ $t('schedule.setting.addPeriod') }}
        </schedule-button>
      </template>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import moment from 'moment'
import SingleShift from './SingleShift'
import SingleWorkHours from './SingleWorkHours.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ShiftSetting',
  components: {
    SingleShift,
    SingleWorkHours
  },
  props: {
    worktimes: {
      type: Object,
      default: () => {}
    },
    collapseAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      innerWorktimes: {},
      allItems: [0, 1, 2, 3, 4, 5, 6],
      activeCollapseItems: [0, 1, 2, 3, 4, 5, 6]
    }
  },
  computed: {
    ...mapGetters('scheduleSetting', ['isYKSchedule'])
  },
  watch: {
    collapseAll: {
      immediate: true,
      handler (value) {
        this.activeCollapseItems = value ? [] : this.allItems
      }
    }
  },
  mounted () {
    this.innerWorktimes = JSON.parse(JSON.stringify(this.worktimes))
    Object.values(this.innerWorktimes).forEach(weekday => {
      weekday.hours = weekday.hours ?? 0
    })
  },
  methods: {
    addShift (dayIndex) {
      this.innerWorktimes[dayIndex].ranges.push({
        startTime: moment().startOf('day').format('HH:mm'),
        endTime: moment().startOf('day').format('HH:mm')
      })
      this.emitData()
    },
    deleteShift (day, shiftIndex) {
      this.innerWorktimes[day].ranges.splice(shiftIndex, 1)
      this.emitData()
    },
    emitData () {
      this.$emit('update:worktimes', this.innerWorktimes)
    }
  }
}
</script>

<style lang="scss" scoped>

.single-shift {
  margin-bottom: 8px;
  margin-right: 24px;
}

.default-button {
  display: block;
  margin-top: 8px;
}

</style>
