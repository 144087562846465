<template>
  <div class="exception-setting">
    <div class="setting__title">
      {{ title }}
    </div>
    <div class="setting__content">
      <span class="reminding">
        <i class="el-icon-warning-outline" />
        {{ $t('schedule.setting.availableWithinPeriod') }}
      </span>
      <div
        v-for="(period, index) in periods"
        :key="index + periods.length"
        class="time__wrapper"
      >
        <schedule-date-picker
          :deletable="true"
          :value="[period.startDatetime, period.endDatetime]"
          :picker-options="pickerOptions"
          :type="isYKSchedule ? 'daterange' : 'datetimerange'"
          :format="isYKSchedule ? 'yyyy/MM/dd' : 'yyyy/MM/dd HH:mm'"
          :value-format="isYKSchedule ? 'yyyy/MM/dd' : 'yyyy/MM/dd HH:mm'"
          @input="changePeriod($event, index)"
          @delete="deletePeriod(index)"
        />
      </div>
      <div class="add-btn__wrapper">
        <schedule-button
          class="add-btn"
          type="secondary"
          @click="addPeriod"
        >
          <i class="el-icon-plus" />
          {{ $t('schedule.setting.addPeriod') }}
        </schedule-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ExceptionTimeSetting',
  props: {
    title: {
      type: String,
      default: ''
    },
    periods: {
      type: Array,
      default: () => []
    },
    startDate: {
      type: String,
      default: ''
    },
    scheduleDays: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          const y = this.startDate.split('/')[0]
          const m = this.startDate.split('/')[1] - 1
          const d = this.startDate.split('/')[2]
          return time.getTime() < moment([y, m, d]).valueOf() ||
                 time.getTime() > moment([y, m, d]).add(this.scheduleDays, 'days').valueOf()
        }
      }
    }
  },
  computed: {
    ...mapGetters('scheduleSetting', ['isYKSchedule'])
  },
  methods: {
    addPeriod (index) {
      this.periods.push({
        startDatetime: '',
        endDatetime: ''
      })
    },
    deletePeriod (index) {
      this.periods.splice(index, 1)
    },
    changePeriod (newVal, index) {
      this.$set(this.periods[index], 'startDatetime', newVal[0])
      this.$set(this.periods[index], 'endDatetime', newVal[1])
    }
  }
}
</script>

<style lang="scss" scoped>
.exception-setting {
  align-items: flex-start;
  display: flex;
  margin-top: 24px;
  width: 100%;

  .setting__title {
    flex: 0 0 140px;
    font-size: 14px;
    line-height: 32px;
  }

  .setting__content {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
  }
}

.time__wrapper {
  align-items: center;
  display: inline-flex;
  margin-bottom: 16px;
  margin-right: 16px;
}

.add-btn__wrapper {
  flex-basis: 100%;
}

.reminding {
  color: var(--color-warning);
  flex-basis: 100%;
  font-size: 12px;
  line-height: 32px;
}
</style>
