<template>
  <div class="single-work-hours">
    <el-input-number
      v-model="syncHours"
      :min="0"
      :max="24"
      class="ss-input-number"
    />
    <span class="single-work-hours__suffix">
      {{ $t('schedule.base.hour') }}
    </span>
    <span class="single-work-hours__reminder">
      （{{ $t('schedule.setting.hoursSettingReminding') }}）
    </span>
  </div>
</template>

<script>
export default {
  name: 'SingleWorkHours',
  props: {
    hours: {
      type: Number,
      default: null
    }
  },
  computed: {
    syncHours: {
      get () {
        return this.hours
      },
      set (val) {
        this.$emit('update:hours', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.single-work-hours {
  &__suffix {}
  &__reminder {
    color: #CCC;
    font-size: 12px;
  }
}
</style>
