<template>
  <div>
    <!-- 基本設定 -->
    <div class="ss-setting ss-setting--basic">
      <div class="ss-setting__header">
        <h2 class="header__title">
          {{ $t('schedule.setting.title') }}
        </h2>
        <div class="header__action">
          <schedule-button
            v-if="isShowSaveButton"
            @click="saveSetting"
          >
            {{ $t('schedule.button.save') }}
          </schedule-button>
        </div>
      </div>
      <error-messages
        v-if="settingInfo && displayErrorMessages.length > 0"
        :error-messages="displayErrorMessages"
      />
      <div class="ss-setting__body">
        <!-- 鈺齊廠區設定（待整併） -->
        <section
          v-if="isYKSchedule"
          class="body__block"
        >
          <h3 class="block__title">
            {{ $t('schedule.yukiAdjustJob.factorySetting') }}
          </h3>
          <div class="block__form">
            <div class="form__item">
              <div class="form__label">
                *{{ $t('schedule.yukiAdjustJob.factoryName') }}
              </div>
              <div class="form__content">
                <spinner v-if="isCheckingYKFactorySiteUpdatable" />
                <template v-else>
                  <schedule-input
                    v-model="settingInfo.factorySite"
                    :disabled="!isYKFactorySiteUpdatable"
                  />
                  <span class="reminding">
                    <i class="el-icon-warning-outline" />
                    {{ $t('schedule.yukiAdjustJob.factoryNameSettingReminder') }}
                  </span>
                </template>
              </div>
            </div>
          </div>
        </section>
        <!-- 單次排程長度設定 -->
        <section class="body__block body__block--duration">
          <h3 class="block__title">
            {{ $t('schedule.setting.init') }}
          </h3>
          <div class="block__form">
            <div class="form__item">
              <div class="form__label">
                *{{ $t('schedule.setting.scheduleDuration') }}
              </div>
              <div class="form__content">
                <el-input-number
                  v-model="settingInfo.scheduleDays"
                  :min="1"
                  class="ss-input-number"
                />
                <span class="schedule-duration__input-unit">{{ $t('schedule.base.day') }}</span>
              </div>
            </div>
            <div
              v-if="solutionInfo"
              class="form__item"
            >
              <div class="form__label">
                *{{ $t('schedule.simulation.startDatetime') }}
              </div>
              <div class="form__content">
                <schedule-date-picker
                  v-model="settingInfo.scheduleStartDate"
                  :picker-options="pickerOptions"
                  format="yyyy/MM/dd"
                  value-format="yyyy/MM/dd"
                  type="date"
                />
              </div>
            </div>
          </div>
        </section>
        <!-- 上班時段設定 -->
        <section
          v-if="settingInfo.worktimes"
          class="body__block body__block--shift"
        >
          <h3 class="block__title">
            {{ $t('schedule.setting.workingHoursSetting') }}
            <i
              :class="{'is-rotate': collapseAll.worktimes}"
              class="block__collapse-controller el-icon-arrow-down"
              @click="toggleCollapse('worktimes')"
            />
            <span class="reminding">
              <i class="el-icon-warning-outline" />
              {{ $t('schedule.setting.useMilitaryTime') }}
            </span>
          </h3>
          <div class="block__form">
            <shift-setting
              :worktimes.sync="settingInfo.worktimes"
              :collapse-all="collapseAll.worktimes"
            />
          </div>
          <div
            v-if="solutionInfo && !isYKSchedule"
            class="block__form"
          >
            <exception-time-setting
              :start-date="settingInfo.scheduleStartDate"
              :schedule-days="settingInfo.scheduleDays"
              :periods="settingInfo.overtimes"
              :title="$t('schedule.simulation.overTimeShift')"
            />
          </div>
          <div
            v-if="solutionInfo"
            class="block__form"
          >
            <exception-time-setting
              :start-date="settingInfo.scheduleStartDate"
              :schedule-days="settingInfo.scheduleDays"
              :periods="settingInfo.leavetimes"
              :title="$t('schedule.simulation.leaveTimeShift')"
            />
          </div>
        </section>
        <!-- 機台排除事件設定 -->
        <section
          v-if="!isYKSchedule"
          class="body__block body__block--equipment"
        >
          <h3 class="block__title">
            {{ $t('schedule.setting.machine') }}
            <i
              :class="{'is-rotate': collapseAll.excludedPeriod}"
              class="block__collapse-controller el-icon-arrow-down"
              @click="toggleCollapse('excludedPeriod')"
            />
            <span class="reminding">
              <i class="el-icon-warning-outline" />
              {{ $t('schedule.setting.useMilitaryTime') }}
            </span>
          </h3>
          <div class="block__form">
            <spinner v-if="isLoadingEquipments" />
            <div
              v-else-if="!equipments"
              class="empty-block"
            >
              {{ $t('schedule.setting.equipmentInfoIsUnbound') }}
            </div>
            <exclude-setting
              v-else
              :excluded-equipments.sync="settingInfo.excludeEquipments"
              :equipments="equipments"
              :collapse-all="collapseAll.excludedPeriod"
            />
          </div>
        </section>
        <!-- KPI 設定 -->
        <section
          v-if="settingInfo.kpiSetting && !isYKSchedule"
          class="body__block body__block--kpi"
        >
          <h3 class="block__title">
            {{ $t('schedule.setting.simulationKPI') }}
            <span class="reminding">
              <i class="el-icon-warning-outline" />
              {{ $t('schedule.setting.noReapeatNumber') }}
            </span>
          </h3>
          <kpi-setting :setting.sync="settingInfo.kpiSetting" />
        </section>
      </div>
    </div>
    <!-- 滾動排程設定 -->
    <div
      v-if="!isYKSchedule"
      class="ss-setting ss-setting--rolling"
    >
      <div class="ss-setting__header">
        <h2 class="header__title">
          {{ $t('schedule.rolling.rollingSetting') }}
        </h2>
      </div>
      <div class="ss-setting__body">
        <!-- 滾動排程方式設定 -->
        <section class="body__block">
          <div class="block__title">
            {{ $t('schedule.rolling.rollingTypes') }}
          </div>
          <rolling-type-setting
            :original-setting="settingInfo.incompleteAction"
            @change="settingInfo.incompleteAction = $event"
          />
        </section>
        <!-- 自動更新生產狀況 -->
        <section
          v-if="!solutionInfo"
          class="body__block"
        >
          <div class="block__title">
            {{ $t('schedule.setting.autoUpdateProduceStatus') }}
            <div class="reminder">
              <a
                class="link"
                @click="isShowScheduleProjectRelationDialog = true"
              >
                {{ $t('schedule.setting.bindDataframe') }}
              </a>
              <div
                v-if="!isBoundWithProductionProgress"
                class="highlight"
              >
                <svg-icon icon-class="alert-circle-outline" />
                {{ $t('schedule.setting.bindProductionProgressReminder') }}
              </div>
            </div>
          </div>
          <auto-update-produce-status-setting
            :original-setting="settingInfo.progressUpdateSetting"
            :is-bound-with-production-progress="isBoundWithProductionProgress"
            @change="settingInfo.progressUpdateSetting = $event"
          />
          <schedule-project-relation-dialog
            v-if="isShowScheduleProjectRelationDialog"
            :project-info="getCurrentProject"
            @close="isShowScheduleProjectRelationDialog = false"
            @setIsBoundWithProductionProgress="isBoundWithProductionProgress = $event"
          />
        </section>
        <!-- 生產延遲通知數量設定 -->
        <section
          v-if="!solutionInfo"
          class="body__block"
        >
          <div class="block__title">
            {{ $t('schedule.rolling.delayNotificationManagement') }}
            <div
              v-if="settingInfo.alertSetting.alertType === ALERT_TYPE.ALERT_OFF"
              class="reminder"
            >
              <div class="highlight">
                <svg-icon icon-class="alert-circle-outline" />
                {{ $t('schedule.setting.activeDelayNotifyReminder') }}
              </div>
            </div>
          </div>
          <rolling-delay-criteria-setting
            :original-setting="settingInfo.alertSetting"
            @change="settingInfo.alertSetting = $event"
          />
        </section>
      </div>
    </div>
    <!-- 示警通知功能設定 -->
    <div
      v-if="!solutionInfo && !isYKSchedule"
      class="ss-setting"
    >
      <div class="ss-setting__header">
        <h2 class="header__title">
          {{ $t('schedule.notification.setting') }}
          <schedule-button
            @click="isShowGroupSettingDialog = true"
          >
            {{ $t('schedule.setting.groupManagement') }}
          </schedule-button>
        </h2>
      </div>
      <div class="ss-setting__body">
        <div class="body__block">
          <div class="block__title">
            {{ $t('schedule.notification.targetSetting') }}
          </div>
          <notification-setting
            :original-setting="settingInfo.alertEventSetting"
            @toggleActive="onNotificationActivenessChanged($event)"
          />
        </div>
      </div>
      <group-setting-dialog
        v-if="isShowGroupSettingDialog"
        :notification-setting="settingInfo.alertEventSetting"
        @close="closeGroupSetting"
      />
    </div>
  </div>
</template>

<script>
import ShiftSetting from './components/shiftSetting/ShiftSetting'
import ExcludeSetting from './components/excludeSetting/ExcludeSetting'
import KpiSetting from './components/kpiSetting/KpiSetting'
import ExceptionTimeSetting from '@/schedule/pages/simulation/setting/components/ExceptionTimeSetting'
import RollingTypeSetting from './components/rollingSetting/RollingTypeSetting'
import RollingDelayCriteriaSetting from './components/rollingSetting/RollingDelayCriteriaSetting'
import AutoUpdateProduceStatusSetting from './components/rollingSetting/AutoUpdateProduceStatusSetting'
import NotificationSetting from './components/notificationSetting/NotificationSetting'
import GroupSettingDialog from './components/groupSetting/GroupSettingDialog'
import ScheduleProjectRelationDialog from '../projectManagement/components/dialog/relation/ScheduleProjectRelationDialog.vue'
import ErrorMessages from './components/ErrorMessages'
import { fetchDataBoundStatus } from '@/schedule/API/Project'
import { getYKFactorySiteUpdatable } from '@/schedule/API/Setting'
import { mapState, mapGetters } from 'vuex'
import { getBaseSettingErrorMessages } from '@/schedule/utils/settingValidator'
import { DATAFRAME_STATUS, NOTIFICATION, ALERT_TYPE } from '@/schedule/utils/enum'
import { Message } from 'element-ui'

export default {
  name: 'BaseSetting',
  inject: {
    // 無值：後台設定
    // 有值：模擬頁面
    $solutionInfo: {
      default: null
    },
    $solutionSequence: {
      default: null
    }
  },
  components: {
    ShiftSetting,
    ExcludeSetting,
    KpiSetting,
    ExceptionTimeSetting,
    RollingTypeSetting,
    RollingDelayCriteriaSetting,
    AutoUpdateProduceStatusSetting,
    NotificationSetting,
    GroupSettingDialog,
    ScheduleProjectRelationDialog,
    ErrorMessages
  },
  data () {
    return {
      isLoadingEquipments: true,
      isProcessing: false,
      isShowGroupSettingDialog: false,
      isShowScheduleProjectRelationDialog: false,
      isBoundWithProductionProgress: false,
      isCheckingYKFactorySiteUpdatable: true,
      isYKFactorySiteUpdatable: false,
      settingInfo: {},
      prevSetting: {},
      errorMessages: [],
      collapseAll: {
        // 後台設定預設展開區塊，模擬頁面預設收合區塊
        worktimes: Boolean(this.solutionInfo),
        excludedPeriod: Boolean(this.solutionInfo)
      },
      ALERT_TYPE,
      pickerOptions: {
        disabledDate: (time) => {
          return this.isYKSchedule ? time.getFullYear() < 2021 : false
        }
      }
    }
  },
  computed: {
    ...mapState('simulation', ['planId', 'solutions']),
    ...mapState('scheduleSetting', ['scheduleProjectId', 'hasCurrentPlan', 'equipments', 'defaultSetting']),
    ...mapGetters('scheduleSetting', ['getCurrentProject', 'isYKSchedule']),
    solutionInfo () {
      return this.$solutionInfo ? this.$solutionInfo() : null
    },
    solutionSequence () {
      return this.$solutionSequence ? this.$solutionSequence() : null
    },
    isShowSaveButton () {
      if (!this.solutionInfo) return true
      return this.displayErrorMessages.length > 0
    },
    displayErrorMessages () {
      return this.solutionInfo ? this.settingInfo.errorMessages : this.errorMessages
    }
  },
  watch: {
    settingInfo: {
      deep: true,
      handler (data) {
        if (!this.solutionSequence) return
        this.$store.commit('simulation/updateSolutionBySequence', {
          sequence: this.solutionSequence,
          data
        })
      }
    }
  },
  created () {
    this.fetchFiles()
    this.initSettingInfo(this.solutionInfo || this.defaultSetting)
    if (this.isYKSchedule) this.checkYKFactorySiteUpdatable()
  },
  methods: {
    initSettingInfo (prevSetting) {
      this.prevSetting = JSON.parse(JSON.stringify(prevSetting))
      this.settingInfo = prevSetting
      if (!this.isYKSchedule) this.formatAlertEventSetting()
    },
    fetchFiles () {
      fetchDataBoundStatus(this.scheduleProjectId)
        .then(files => {
          files.forEach(file => {
            if (file.dataframeStatus === DATAFRAME_STATUS.BOUND) {
              switch (file.code) {
                case 'EQUIPMENT': {
                  this.fetchEquipments()
                  break
                }
                case 'PRODUCTION_PROGRESS': {
                  this.isBoundWithProductionProgress = true
                  break
                }
              }
            }
            if (file.dataframeStatus === DATAFRAME_STATUS.UNBOUND) {
              switch (file.code) {
                case 'EQUIPMENT': {
                  this.isLoadingEquipments = false
                }
              }
            }
          })
        })
        .catch(() => {})
        .finally(() => {})
    },
    fetchEquipments () {
      this.isLoadingEquipments = true
      this.$store.dispatch('scheduleSetting/getEquipments')
        .then(equipments => {
          // this.equipments = equipments
          this.$store.commit('scheduleSetting/setEquipments', equipments)
        })
        .catch(() => {})
        .finally(() => this.isLoadingEquipments = false)
    },
    saveSetting () {
      // 表單驗證
      const errorMessages = getBaseSettingErrorMessages(this.settingInfo)
      if (errorMessages.length > 0) {
        this.errorMessages = errorMessages
        if (this.solutionInfo) {
          this.$store.commit('simulation/updateSolutionErrorMessagesBySequence', {
            sequence: this.solutionInfo.sequence,
            errorMessages
          })
        }
        return
      }

      this.errorMessages = []
      if (this.solutionInfo) {
        this.settingInfo.errorMessages = []
        if (this.planId) this.settingInfo.simulated = false
        this.$store.commit('simulation/updateSolutionErrorMessagesBySequence', {
          sequence: this.solutionInfo.sequence,
          errorMessages: []
        })
        Message({
          message: this.$t('schedule.successMessage.settingSaved'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
        return
      }

      this.isProcessing = true

      // NOTICE 邏輯重複，在 sp2 已整進 actions 中
      // 不是鈺齊排程，要還原回僅有 ranges 的 worktimes
      const formattedWorktimes = {}
      if (!this.isYKSchedule) {
        for (const [weekday, shiftInfo] of Object.entries(this.settingInfo.worktimes)) {
          formattedWorktimes[weekday] = shiftInfo.ranges
        }
      }

      const copiedSetting = {
        ...this.settingInfo,
        worktimes: this.isYKSchedule ? this.settingInfo.worktimes : formattedWorktimes,
        projectId:
        this.scheduleProjectId
      }
      this.$store.dispatch('scheduleSetting/setBaseSetting', copiedSetting).then(() => {
        Message({
          message: this.$t('schedule.successMessage.settingSaved'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
        this.$store.commit('scheduleSetting/updateSetting', copiedSetting)
      })
        .catch(() => {})
        .finally(() => this.isProcessing = false)
    },
    formatAlertEventSetting () {
      this.settingInfo.alertEventSetting.forEach(event => {
        switch (event.alertEvent) {
          case NOTIFICATION.DELAY:
            event.isActive = this.settingInfo.alertSetting.alertType !== ALERT_TYPE.ALERT_OFF
        }
      })
    },
    onNotificationActivenessChanged ({ alertEvent, isActive }) {
      switch (alertEvent) {
        case NOTIFICATION.DELAY:
          if (isActive) {
            this.$set(this.settingInfo, 'alertSetting', {
              alertThreshold: this.prevSetting.alertSetting.alertThreshold || '100',
              alertType: this.prevSetting.alertSetting.alertType === ALERT_TYPE.ALERT_OFF
                ? ALERT_TYPE.ALERT_PERCENTAGE
                : this.prevSetting.alertSetting.alertType
            })
          } else {
            this.$set(this.settingInfo, 'alertSetting', {
              alertThreshold: null,
              alertType: ALERT_TYPE.ALERT_OFF
            })
          }
      }
    },
    toggleCollapse (section) {
      this.collapseAll[section] = !this.collapseAll[section]
    },
    closeGroupSetting (shouldRefetch) {
      this.isShowGroupSettingDialog = false
      if (shouldRefetch) this.fetchBackStageSetting()
    },
    checkYKFactorySiteUpdatable () {
      getYKFactorySiteUpdatable(this.scheduleProjectId)
        .then(updatable => {
          this.isYKFactorySiteUpdatable = updatable
        })
        .catch(() => {})
        .finally(() => {
          this.isCheckingYKFactorySiteUpdatable = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.ss-setting {
  padding: 24px;

  &--basic {
    .ss-setting__body {
      display: flex;
      flex-direction: column;

      .body__block {
        &--duration {
          .form__item { flex-basis: 100%; }
        }
      }

      .body__block {
        &--duration { order: 1; }
        &--shift { order: 2; }
        &--overtime { order: 3; }
        &--equipment { order: 4; }
        &--kpi { order: 5; }
        &--url { order: 6; }

        .page--simulation & {
          &--duration { order: 1; }
          &--kpi { order: 2; }
          &--shift { order: 3; }
          &--overtime { order: 4; }
          &--equipment { order: 5; }
          &--url { order: 6; }
        }
      }
    }
  }

  &--rolling {
    .reminder {
      align-items: center;
      display: inline-flex;
      font-size: 12px;
      gap: 16px;
      margin-left: 12px;

      .highlight {
        color: $theme-color-warning;
      }
    }
  }
}

.spinner-block {
  margin: 0 auto;
}
</style>
