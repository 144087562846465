<template>
  <ul class="error-messages__block">
    <li
      v-for="(message, index) in errorMessages"
      :key="index"
      class="error-messages__item"
    >
      <span>{{ message }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    errorMessages: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.error-messages {
  &__block {
    background-color: rgba(255, 102, 92, 0.55);
    border-radius: 8px;
    padding: 12px 12px 12px 36px;
  }

  &__item {
    font-size: 14px;
  }
}
</style>
