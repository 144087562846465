<template>
  <div class="single-machine">
    <div class="reason__wrapper">
      <span class="reason__label">{{ $t('schedule.setting.excludeReason') }}</span>
      <schedule-select
        v-model="innerExcludeReason.reason"
        :options="reasonOptions"
        @input="emitData"
      />
      <span class="reason__label time-period">{{ $t('schedule.setting.excludePeriod') }}</span>
      <schedule-date-picker v-model="excludedPeriod" />
      <i
        v-show="deletable"
        class="icon-remove el-icon-remove-outline"
        @click="$emit('delete')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleExcludedMachine',
  props: {
    excludeReason: {
      type: Object,
      default: () => {}
    },
    deletable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      innerExcludeReason: {},
      reasonOptions: [
        { value: 'repairing', label: this.$t('schedule.setting.repairing') },
        { value: 'testing', label: this.$t('schedule.setting.testing') },
        { value: 'experimenting', label: this.$t('schedule.setting.experimenting') }
      ]
    }
  },
  created () {
    this.innerExcludeReason = JSON.parse(JSON.stringify(this.excludeReason))
  },
  computed: {
    excludedPeriod: {
      get () {
        return [this.innerExcludeReason.startDatetime, this.innerExcludeReason.endDatetime]
      },
      set (newVal) {
        this.innerExcludeReason.startDatetime = newVal ? newVal[0] : ''
        this.innerExcludeReason.endDatetime = newVal ? newVal[1] : ''
        this.emitData()
      }
    }
  },
  methods: {
    emitData () {
      this.$emit('update:exclude-reason', this.innerExcludeReason)
    }
  }
}
</script>

<style lang="scss" scoped>
.single-machine {
  color: var(--color-text);

  .reason__wrapper {
    align-items: center;
    display: flex;
    width: 100%;

    & + .reason__wrapper {
      margin-top: 8px;
    }

    .reason__label {
      margin-right: 16px;

      &.time-period {
        margin-left: 16px;
      }
    }

    .icon-remove {
      cursor: pointer;
      margin-left: auto;
    }
  }
}
</style>
