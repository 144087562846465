<template>
  <div class="block__form">
    <div class="form__item">
      <div class="form__label">
        *{{ $t('schedule.setting.capacity') }}
      </div>
      <div class="form__content">
        <schedule-select
          v-model="innerKpiSetting.capacity"
          :options="priorityOptions"
          @input="emitData"
        />
      </div>
    </div>
    <div class="form__item">
      <div class="form__label">
        *{{ $t('schedule.setting.ofr') }}
      </div>
      <div class="form__content">
        <schedule-select
          v-model="innerKpiSetting.ofr"
          :options="priorityOptions"
          @input="emitData"
        />
      </div>
    </div>
    <div class="form__item">
      <div class="form__label">
        *{{ $t('schedule.setting.cycleTime') }}
      </div>
      <div class="form__content">
        <schedule-select
          v-model="innerKpiSetting.cycleTime"
          :options="priorityOptions"
          @input="emitData"
        />
      </div>
    </div>
    <div class="form__item">
      <div class="form__label">
        *{{ $t('schedule.setting.utilization') }}
      </div>
      <div class="form__content">
        <schedule-select
          v-model="innerKpiSetting.utilization"
          :options="priorityOptions"
          @input="emitData"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KpiSetting',
  props: {
    setting: {
      type: Object,
      default: () => {}
    }
  },
  created () {
    this.innerKpiSetting = JSON.parse(JSON.stringify(this.setting))
  },
  data () {
    return {
      innerKpiSetting: {},
      priorityOptions: [
        { value: 1, lable: '1' },
        { value: 2, lable: '2' },
        { value: 3, lable: '3' },
        { value: 4, lable: '4' }
      ]
    }
  },
  methods: {
    emitData () {
      this.$emit('update:setting', this.innerKpiSetting)
    }
  }
}
</script>

<style lang="scss" scoped></style>
