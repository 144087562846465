<template>
  <div class="excluded-setting">
    <el-collapse
      v-model="activeCollapseItems"
      class="ss-collapse"
    >
      <el-collapse-item
        v-for="(equipment, equipmentIndex) in innerExcludedEquipments"
        :key="`${equipmentIndex}-${equipment.reasons.length}`"
        :title="equipment.equipment"
        :name="equipment.equipment"
      >
        <template slot="title">
          <span class="single-machine__label">{{ $t('schedule.setting.excludedMachine') }}</span>
          <schedule-select
            v-model="equipment.equipment"
            :options="equipmentOptions"
            filterable
            @input="emitData"
          />
        </template>
        <!-- 這邊不寫 .sync="reason" 因 eslint vue/valid-v-bind-sync 會報錯-->
        <single-excluded-machine
          v-for="(reason, reasonIndex) in equipment.reasons"
          :key="reasonIndex"
          :exclude-reason.sync="equipment.reasons[reasonIndex]"
          @update:exclude-reason="emitData"
          :deletable="equipment.reasons.length > 1"
          @delete="deleteSetting(equipmentIndex, reasonIndex)"
        />
        <div class="excluded-setting__footer">
          <schedule-button
            type="secondary"
            @click="addExcludedPeriod(equipmentIndex)"
          >
            <i class="el-icon-plus" />
            {{ $t('schedule.setting.addExcludedPeriod') }}
          </schedule-button>
          <label
            class="excluded-setting__remove-box"
            @click="deleteEquipment(equipmentIndex)"
          >
            {{ $t('schedule.setting.deleteEquipment') }}
            <i class="el-icon-delete" />
          </label>
        </div>
      </el-collapse-item>
    </el-collapse>
    <schedule-button
      :is-disabled="!isAddable"
      type="secondary"
      class="save-btn"
      @click="addEquipment"
    >
      <i class="el-icon-plus" />
      {{ $t('schedule.setting.addEquipment') }}
    </schedule-button>
    <span
      v-show="!isAddable"
      class="reminding"
    >
      <i class="el-icon-warning-outline" />
      {{ $t('schedule.base.noAvailableEquipmentToExclude') }}
    </span>
  </div>
</template>

<script>
import SingleExcludedMachine from './SingleExcludedMachine'
import moment from 'moment'

export default {
  name: 'ExcludeSetting',
  components: {
    SingleExcludedMachine
  },
  props: {
    excludedEquipments: {
      type: Array,
      default: () => []
    },
    equipments: {
      type: Array,
      default: () => []
    },
    collapseAll: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.innerExcludedEquipments = JSON.parse(JSON.stringify(this.excludedEquipments))
  },
  data () {
    return {
      innerExcludedEquipments: [],
      activeCollapseItems: [],
      defaultExcludedVal: {
        reason: 'repairing',
        startDatetime: moment().startOf('day').format('YYYY/MM/DD HH:mm'),
        endDatetime: moment().startOf('day').format('YYYY/MM/DD HH:mm')
      }
    }
  },
  computed: {
    selectedEquipments () {
      return this.innerExcludedEquipments.map(e => e.equipment)
    },
    equipmentOptions () {
      return this.equipments.map(e => ({ value: e, label: e }))
    },
    isAddable () {
      return this.equipments.length > this.innerExcludedEquipments.length
    }
  },
  watch: {
    collapseAll: {
      immediate: true,
      handler (value) {
        this.activeCollapseItems = value ? [] : this.selectedEquipments
      }
    }
  },
  methods: {
    deleteSetting (equipmentIndex, reasonIndex) {
      this.innerExcludedEquipments[equipmentIndex].reasons.splice(reasonIndex, 1)
      this.emitData()
    },
    addExcludedPeriod (equipmentIndex) {
      this.innerExcludedEquipments[equipmentIndex].reasons.push({ ...this.defaultExcludedVal })
      this.emitData()
    },
    addEquipment () {
      if (!this.isAddable) return

      // 找出第一個還沒有設定排除事件的機台
      const selectedEquipmentIds = this.innerExcludedEquipments.map(item => item.equipment)
      const firstNotExcludedEquipment = this.equipments.find(item => !selectedEquipmentIds.includes(item))

      if (!firstNotExcludedEquipment) return
      this.innerExcludedEquipments.push({
        equipment: firstNotExcludedEquipment,
        reasons: [{ ...this.defaultExcludedVal }]
      })
      this.activeCollapseItems.push(firstNotExcludedEquipment)
      this.emitData()
    },
    deleteEquipment (equipmentIndex) {
      this.innerExcludedEquipments.splice(equipmentIndex, 1)
      this.emitData()
    },
    emitData () {
      this.$emit('update:excludedEquipments', this.innerExcludedEquipments)
    }
  }
}
</script>

<style lang="scss" scoped>
.excluded-setting {
  width: 100%;

  ::v-deep .el-collapse-item {
    &__content {
      margin-top: 8px;
    }
  }

  &__footer {
    align-items: center;
    color: var(--color-text);
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  &__remove-box {
    cursor: pointer;
  }
}

::v-deep .single-machine {
  padding: 4px 8px;

  &:nth-child(even) {
    background-color: rgba(50, 75, 78, 0.6);
  }

  &:nth-child(odd) {
    background-color: rgba(35, 61, 64, 0.6);
  }

  &__label {
    margin-right: 16px;
  }
}

.save-btn {
  margin-top: 16px;
}

::v-deep .schedule-select {
  padding-bottom: 0;
}
</style>
